import React from "react"
import "../global.css"
import End from "../subpages/End";
import Header from "../components/Header";
import Footer from "../components/Footer";
import SeoHelmet from "../components/seo"
import { StyledLink } from "../components/common"
import { StaticImage } from "gatsby-plugin-image"

function Region() {
  return (
    <div>
      <SeoHelmet 
        title="Ferien in Salzburg und Werfenweng - Haus Gruber Ferienwohnungen"
        description="Verbringen Sie Ihre Ferien in Salzburg, in der bezaubernden Umgebung von Werfenweng mit seiner atemberaubenden Aussicht auf die Berge der Region."
      />
      <Header />
      <div className="container">
        <div className="flexbox">
          <h1 className="text-center my-5">Ferien ins Salzburg und Werfenweng</h1>
          <div className="row no-gutters position-relative">
            <div className="col-md-6 mb-4 p-md-4">
                <StaticImage
                  src="../images/region/alm.jpg"
                  alt="Werfenweng - der idyllische Tourismusort inmitten der Salzburger Alpenwelt"
                  className="w-100"
                  placeholder="blurred"
                  layout="fixed"
                  width={400 }
                  height={267}
                />
              </div>
            <div className="col-md-6 p-md-4">
              <h5>Werfenweng - der idyllische Tourismusort inmitten der Salzburger Alpenwelt</h5>
              <p>Wer die Schönheit der Natur sucht und schätzt, ist in Werfenweng genau richtig. Von unserer Pongauer Gemeinde aus können Urlauber das Salzburger Land mit all seinen Sehenswürdigkeiten und Sportangeboten perfekt erkunden. Werfenweng liegt inmitten der Bergwelt und bezaubert mit einem umwerfenden Bergpanorama. Der Ort selbst liegt bereits auf rund 900 m Seehöhe.</p>
            </div>
          </div>
          <div className="row no-gutters position-relative">
            <div className="col-md-6 mb-4 p-md-4 order-md-last">
              <StaticImage
                  src="../images/region/para.jpg"
                  alt="Im Sommer und im Winter einen unvergesslichen Urlaub in Werfenweng genießen."
                  className="w-100"
                  placeholder="blurred"
                  layout="fixed"
                  width={400 }
                  height={267}
                />
            </div>
            <div className="col-md-6 p-md-4">
              <h5>Im Sommer und im Winter einen unvergesslichen Urlaub in Werfenweng genießen.</h5>
              <p>Rund um Werfenweng gibt es zahlreiche Ausflugsziele, die eine Erkundung mehr als wert sind. Wer bei uns im Sommer urlaubt, sollte jedenfalls die Eisriesenwelt und die Burg Hohenwerfen, beides im nahe gelegenen Werfen, besuchen. Sehenswert ist auch die Greifvogelschau, die es sowohl auf der Burg Hohenwerfen als auch im Ort Werfenweng gibt. Wanderer können in der Wanderarena Werfenweng die Alpenwelt durchstreifen - am besten von der Bergstation der IKARUS Bahn auf 1834 m Seehöhe aus. Zur Abkühlung lädt ein Badesee mit Beachvolleyballplatz ein. Außerdem gibt es in der Umgebung zahlreiche Absprungstellen für Paragleiter.</p>
            </div>
          </div>
          <div className="row no-gutters position-relative">
            <div className="col-md-6 mb-4 p-md-4">
              <StaticImage
                  src="../images/region/gondel.jpg"
                  alt="Skifahren, Langlaufen, Rodeln, Eislaufen und Skitouren gehen in der Bergwelt von Werfenweng"
                  className="w-100"
                  placeholder="blurred"
                  layout="fixed"
                  width={400 }
                  height={267}
                />
              </div>
            <div className="col-md-6 p-md-4">
              <h5>Skifahren, Langlaufen, Rodeln, Eislaufen und Skitouren gehen in der Bergwelt von Werfenweng</h5>
              <p>Winterurlauber können bei uns im Ort in die Langlaufloipe einsteigen, Schneeschuhwandern, Skifahren und Snowboarden im Familienskigebiet Werfenweng (9 Liftanlagen, 27 km Pisten aller Schwierigkeitsgrade), Rodeln, Skitouren gehen und Eislaufen. Empfehlenswert ist auch eine Fahrt mit der Pferdekutsche. Ein Highlight im Veranstaltungskalender unseres Ortes ist mit Sicherheit der stimmungsvolle Adventmarkt am Dorfplatz sowie das internationale Hundeschlittenrennen im Jänner.</p>
            </div>
          </div>
          <div className="row no-gutters position-relative justify-content-center">
            <div className="col-md-12 m-4 p-md-4 text-center">
              <StyledLink 
                href="https://werfenweng.skiperformance.com/de/sommer/store#/de/sommer/buy?bookable_y_n_a=a"
                target="_blank"
                rel="noopener"
                >
                  Jetzt Angebote buchen
                </StyledLink>
            </div>
          </div>
        </div>
      </div>
      <End/>
      <Footer/>
    </div>
  )
}

export default Region
